@import 'src/styles/media';

.TrustedSlider {
  margin-right: 16px;
  margin-left: 16px;

  @include media-up(breakpoint-md) {
    margin-left: 24px;
  }

  @media screen and (min-width: 532px) {
    margin-right: 0;
  }

  @media screen and (min-width: 1240px) {
    margin-left: calc(((100vw - 1288px) / 2) + 18px);
  }
}

.TrustedSlider__link {
  display: flex;
  justify-content: flex-start;
  margin-top: 26px;
}