@import 'src/styles/tokens/colors';

.BlogCardTag {
  border-radius: 6px;
  padding: 4px 10px;
  font-size: 14px;
  white-space: nowrap;
}

.BlogCardTag_blue {
  color: $color-original-blue;
  background: $color-light-blue;
}

.BlogCardTag_yellow {
  color: $color-orange;
  background-color: $color-light-orange;
}

.BlogCardTag_green {
  color: $color-green;
  background: $color-light-green;
}

.BlogCardTag_purple {
  color: #7f0ed8;
  background: #ecd4ff;
}

.BlogCardTag_red {
  color: $color-red;
  background: $color-light-red;
}