@import 'src/styles/media';

.HomeBlogBlock__title {
  margin-bottom: 60px;
  text-align: center;
}

.HomeBlogBlock__grid {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  row-gap: 20px;

  @include media-up(breakpoint-md) {
    grid-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-up(breakpoint-xl) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.HomeBlogBlock__btn {
  margin-top: 26px;
  width: 100%;

  @include media-up(breakpoint-md) {
    margin: 44px auto 0;
    width: fit-content;
  }
}