@import 'src/styles/media';

.HomeStartupBlock {
  padding-top: 46px;
  background: linear-gradient(180.05deg, #fff 0.04%, rgb(223 223 253 / 74%) 17.22%, rgb(224 233 253 / 10%) 90.59%, rgb(255 255 255 / 10%) 99.96%);

  @include media-up(breakpoint-md) {
    padding-top: 66px;
  }
}
