@import 'src/styles/tokens/colors';
@import 'src/styles/media';

.StoryCardLink {
  position: absolute;
  right: -10px;
  top: 6px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #9fa1ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: $color-original-blue;
  background: $color-bg;
  transform: translateY(-50%);

  span {
    display: none;
  }

  @include media-up(breakpoint-md) {
    right: 0;
    top: -10px;
    border-radius: 100px;
    padding: 8px 16px;
    width: auto;
    height: auto;
    transform: none;

    span {
      display: inline;
    }
  }
}

.StoryCardLink__icon {
  @include media-up(breakpoint-md) {
    margin-left: 10px;
  }
}