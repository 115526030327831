@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';

.OptionsCard {
  display: block;
  border-radius: 20px;
  padding: 20px 24px;
  background-color: $color-bg;
  transition: background-color $transition-main, transform $transition-main;

  &:hover {
    background-color: $color-original-blue;
    transform: scale(1.01);

    .OptionsCard__icon,
    .OptionsCard__title {
      color: #fff;
    }
  }
}

.OptionsCard__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}

.OptionsCard__icon {
  width: 60px;
  height: 32px;
  min-width: 60px;
  color: $color-original-blue;
  transition: color $transition-main;
}

.OptionsCard__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  min-width: 44px;
  color: $color-dark-grey;
  background-color: #fff;
  cursor: pointer;
}

.OptionsCard__title {
  margin-bottom: 12px;
  white-space: nowrap;
  color: $color-original-blue;
  transition: color $transition-main;
}

.OptionsCard__footer {
  display: flex;
  align-items: center;
}

.OptionsCard__badge {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 6px 8px;
  white-space: nowrap;
  color: $color-dark-grey;
  background: #fff;

  & + & {
    margin-left: 8px;
  }
}

.OptionsCard__starIcon {
  margin-right: 6px;
  color: $color-original-blue;
}