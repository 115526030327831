@import 'src/styles/tokens/colors';
@import 'src/styles/mixins';
@import 'src/styles/media';

.IntroBlockForm {
  position: relative;
  padding-bottom: 80px;

  @include media-up(breakpoint-md) {
    min-height: 720px;
  }
}

.IntroBlockForm__img {
  display: none;

  @include media-up(breakpoint-md) {
    position: absolute;
    right: -22%;
    bottom: -124px;
    display: block;
  }

  @include media-up(breakpoint-lg) {
    right: -12%;
    bottom: 20px;
  }

  @include media-up(breakpoint-xl) {
    right: -8%;
  }
}

.IntroBlockForm__imgInner {
  position: relative;
  width: 982px;
  height: 562px;
}

.IntroBlockForm__arrows {
  display: none;

  // stylelint-disable
  @media screen and (min-width: 592px) {
    display: block;
    position: absolute;
    left: -24px;
    top: -47px;
    z-index: 1;
    height: 278px;
  }

  @include media-up(breakpoint-md) {
    left: 0;
    top: -2px;
    height: 432px;
  }

  @include media-up(breakpoint-lg) {
    top: -72px;
  }

  @media screen and (min-width: 1240px) {
    top: -32px;
  }
}

.IntroBlockForm__title {
  position: relative;
  margin-bottom: 22px;
  font-size: 36px;
  line-height: 44px;
  color: $color-dark-blue;

  br {
    display: none;
  }

  @media screen and (min-width: 592px) {
    padding-left: 24px;
  }

  @include media-up(breakpoint-md) {
    padding-left: 52px;
    font-size: 60px;
    line-height: 72px;
  }

  @include media-up(breakpoint-lg) {
    margin-bottom: 24px;

    br {
      display: block;
    }
  }

  @media screen and (min-width: 1240px) {
    font-size: 70px;
    line-height: 92px;
  }
}

.IntroBlockForm__list {
  @include list-reset;

  @media screen and (min-width: 592px) {
    padding-left: 56px;
  }

  @include media-up(breakpoint-md) {
    padding-left: 96px;
  }
}

.IntroBlockForm__listItem {
  color: $color-dark-blue;
  font-size: 16px;

  & + & {
    margin-top: 6px;
  }

  @include media-up(breakpoint-md) {
    font-size: 22px;
    & + & {
      margin-top: 16px;
    }
  }
}

.IntroBlockForm__form {
  margin-top: 40px;

  button {
    width: 100%;
  }
  
  @media screen and (min-width: 592px) {
    padding-left: 56px;

    button {
      width: auto;
    }
  }
}

.IntroBlockForm__inputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media screen and (min-width: 592px) {
    margin-bottom: 24px;
    align-items: center;
    flex-direction: row;
  }
}

.IntroBlockForm__input {
  margin-bottom: 16px;

  @media screen and (min-width: 592px) {
    margin-right: 22px;
    width: 332px;
    margin-bottom: 0;
  }
}

.IntroBlockForm__link {
  color: $color-dark-grey;
  text-align: center;

  button {
    color: $color-original-blue !important;
    cursor: pointer;
    width: auto;
    z-index: 2;
    position: relative;
    @include button-reset;
  }

  @media screen and (min-width: 592px) {
    text-align: left;
  }
}

.IntroBlockForm__listItemInner {
  @media screen and (max-width: 592px) {
    padding-left: 28px;
    position: relative;
  }
}

.IntroBlockForm__listItemIcon {
  display: none;
  
  @media screen and (max-width: 592px) {
    position: absolute;
    display: block;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.IntroBlockForm__btn{  
  padding: 12px 44px;
  font-weight: 400;
  font-size: 18px;
  height: auto;
}