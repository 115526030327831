@import 'src/styles/media';

.StoryCardLines__circle {
  content: '';
  position: absolute;
  z-index: 2;
  border: 1px solid #9fa1ff;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: #fff;
}

.StoryCardLines__topLine {
  position: absolute;
  left: 30%;
  right: -8px;
  top: 12px;
  bottom: 10%;
  z-index: 1;
  border-top: 1px solid #9fa1ff;
  border-right: 1px solid #9fa1ff;
  border-top-right-radius: 20px;

  &::before {
    top: -3px;

    @extend .StoryCardLines__circle;
  }

  &::after {
    right: -3px;
    bottom: -3px;

    @extend .StoryCardLines__circle;
  }

  @include media-up(breakpoint-sm) {
    left: 16%;
  }
}

.StoryCardLines__bottomLine {
  position: absolute;
  left: -8px;
  right: 10%;
  top: 34%;
  bottom: -8px;
  z-index: 1;
  border-bottom: 1px solid #9fa1ff;
  border-left: 1px solid #9fa1ff;
  border-bottom-left-radius: 20px;

  &::before {
    left: -3px;
    top: 0;

    @extend .StoryCardLines__circle;
  }

  &::after {
    right: -3px;
    bottom: -3px;

    @extend .StoryCardLines__circle;
  }

  // @include media-up(breakpoint-sm) {
  //   left: 16%;
  // }
}