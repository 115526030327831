@import 'src/styles/tokens/colors';
@import 'src/styles/media';

.HomeBrowseOptions {
  display: grid;
  grid-template-columns: 1fr;
  gap: 14px;

  @include media-up(breakpoint-md) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  @include media-up(breakpoint-lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-up(breakpoint-xl) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.HomeBrowseOptions__showMore {
  color: $color-dark-grey;
}