@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';
@import 'src/styles/media';

.PlatformCard {
  transition: transform $transition-main;
}

.PlatformCard__icon {
  margin-bottom: 12px;
  width: 70px;
  height: 70px;

  @include media-up(breakpoint-md) {
    margin-bottom: 14px;
    width: 186px;
    height: 186px;
  }
}

.PlatformCard__title {
  width: 100%;
  color: $color-dark-blue;
}

.PlatformCard__title_accent {
  color: $color-original-blue;
}

.PlatformCard__description {
  margin-top: 8px;
  color: $color-dark-grey;
}