@import 'src/styles/media';

.HomeDevelopersBlock {
  display: flex;
  flex-direction: column;

  @include media-up(breakpoint-md) {
    margin-top: 130px;
  }

  @include media-up(breakpoint-xl) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 160px;
  }
}

.HomeDevelopersBlock__block {
  @include media-up(breakpoint-xl) {
    &:first-of-type {
      max-width: 480px;
    }
  }

  @include media-up(breakpoint-xll) {
    &:first-of-type {
      max-width: 528px;
    }
  }
}

.HomeDevelopersBlock__block_text {
  order: -1;
  margin-bottom: 58px;

  @include media-up(breakpoint-xl) {
    order: unset;
    margin-bottom: 0;
  }
}