@import 'src/styles/tokens/colors';
@import 'src/styles/media';
@import 'src/styles/mixins';

.IntroBlockTrusted {
  margin-top: auto;
}

.IntroBlockTrusted__title {
  margin-bottom: 20px;
  text-align: center;
  color: $color-dark-blue;

  @include media-up(breapoint-md) {
    margin-top: 120px;
  }
}

.IntroBlockTrusted__cards {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, minmax(120px, 180px));
  grid-template-rows: repeat(2, 100px);
  padding-bottom: 32px;
  row-gap: 12px;

  @include media-up(breakpoint-md) {
    column-gap: 8px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    row-gap: 0;
  }

  @include media-up(breakpoint-lg) {
    column-gap: 12px;
    grid-template-columns: repeat(6, 1fr);
  }
}

.IntroBlockTrusted__card {
  @include svg-icon;

  margin: auto;
}