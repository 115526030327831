@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';

.DeveloperSlideArrows {
  display: flex;
  align-items: center;
}

.DeveloperSlideArrows__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: #8687ba;
  transition: background-color $transition-main, transform $transition-main;
  cursor: pointer;

  &:hover {
    background-color: $color-original-blue;
    transform: scale(1.05);
  }

  & + & {
    margin-left: 16px;
  }
}

.DeveloperSlideArrows__arrow_left {
  svg {
    transform: rotate(90deg);
  }
}

.DeveloperSlideArrows__arrow_right {
  svg {
    transform: rotate(-90deg);
  }
}