@import 'src/styles/tokens/colors';

.DetailedProfileTitle__title {
  margin-bottom: 14px;
  text-align: center;
}

.DetailedProfileTitle__subTitle {
  margin-bottom: 40px;
  text-align: center;
  color: $color-original-blue;
}