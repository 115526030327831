@import 'src/styles/tokens/colors';
@import 'src/styles/typography';

.StoryCardResult {
  margin-top: auto;
}

.StoryCardResult__result {
  margin-bottom: 10px;
  font-family: $font-family-main;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: $color-dark-blue;
}

.StoryCardResult__text {
  min-height: 72px;
  color: $color-original-blue;
}