@import 'src/styles/tokens/transitions';
@import 'src/styles/tokens/colors';
@import 'src/styles/media';

.TrustedCard {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: transparent;
  transition: background-color $transition-main;

  @include media-up(breakpoint-lg) {
    padding: 16px;
  }
}

.TrustedCard_hover {
  &:hover {
    background-color: #fff;

    .TrustedCard__logo_default {
      display: none;
    }

    .TrustedCard__logo_hover {
      display: block;
    }

    .TrustedCard__link {
      opacity: 1;
    }
  }
}

.TrustedCard__logo {
  width: 100%;
  height: 100%;
}

.TrustedCard__logo_hover {
  display: none;
}

.TrustedCard__link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  color: $color-original-blue;
  opacity: 0;
  transition: opacity $transition-main;
  cursor: pointer;
}

.TrustedCard__linkIcon {
  margin-right: 6px;
  border-radius: 50%;
  color: #fff;
  background-color: $color-original-blue;
}