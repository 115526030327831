@import 'src/styles/media';

.DevelopersSlider {
  overflow-x: hidden;
}

.DevelopersSlider__desktop {
  display: none;

  @include media-up(breakpoint-xl) {
    display: block;
  }
}

.DevelopersSlider__mobile {
  display: block;

  @include media-up(breakpoint-xl) {
    display: none;
  }
}