@import 'src/styles/tokens/colors';
@import 'src/styles/media';

.HomeBrowseStats {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 74px;

  @include media-up(breakpoint-md) {
    overflow-x: hidden;
    margin-top: 0;
    height: 364px;
    background: linear-gradient(180deg, #fff 0%, rgb(255 255 255 / 0%) 17.78%, rgb(255 255 255 / 0%) 82.52%, #fff 100%), linear-gradient(270deg, rgb(223 223 253 / 46%) 0%, rgb(255 255 255 / 27%) 34.84%, rgb(255 255 255 / 33%) 66.1%, rgb(223 223 253 / 46%) 100%);
  }
}

.HomeBrowseStats__content {
  text-align: center;
}

.HomeBrowseStats__text {
  color: $color-dark-blue;

  span {
    color: $color-original-blue;
  }

  & + & {
    margin-top: 12px;
  }
}

.HomeBrowseStats__btn {
  margin: 36px auto 0;
  padding: 12px 44px;
  font-weight: 400;
  font-size: 18px;
  height: auto;
}

.HomeBrowseStats__image {
  position: absolute;
  z-index: 1;
}

.HomeBrowseStats__image_left {
  display: none;

  @include media-up(breakpoint-md) {
    left: -26%;
    display: block;
  }

  @include media-up(breakpoint-lg) {
    left: -18%;
  }

  @include media-up(breakpoint-xl) {
    left: 0;
  }
}

.HomeBrowseStats__image_right {
  display: none;

  @include media-up(breakpoint-md) {
    right: -26%;
    display: block;
  }

  @include media-up(breakpoint-lg) {
    right: -18%;
  }

  @include media-up(breakpoint-xl) {
    right: 0;
  }
}