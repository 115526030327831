.StoryCardVideo {
  margin: 24px 0;
}

.StoryCardVideo__icon {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #9fa1ff;
}

.StoryCardVideo__iconCircle {
  position: absolute;
  top: -4px;
  z-index: 1;
  border: 1px solid #9fa1ff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: #fff;
}

.StoryCardVideo__iconCircle_left {
  left: 0;

  @extend .StoryCardVideo__iconCircle;
}

.StoryCardVideo__iconCircle_right {
  right: 0;

  @extend .StoryCardVideo__iconCircle;
}