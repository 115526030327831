@import 'src/styles/tokens/colors';
@import 'src/styles/media';

.HomeBrowseTitle {
  display: flex;
  flex-direction: column;
  margin-bottom: 44px;

  @include media-up(breakpoint-lg) {
    flex-direction: row;
    align-items: center;
  }
}

.HomeBrowseTitle__highlighted {
  color: $color-original-blue;
}

.HomeBrowseTitle__icon {
  margin-right: 26px;
  margin-bottom: 10px;
  width: 40px;
  height: 40px;

  @include media-up(breakpoint-md) {
    margin-bottom: 0;
    width: 66px;
    height: 66px;
  }
}
