@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';
@import 'src/styles/tokens/shadows';
@import 'src/styles/mixins';

.BlogCard {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 20px 24px;
  min-height: 320px;
  background: $color-bg;
  transition: transform $transition-main;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);

    .BlogCard__arrowWrapper {
      background-color: $color-original-blue;
    }

    .BlogCard__arrow {
      color: #fff;
    }
  }
}

.BlogCard__img {
  position: relative;
  overflow: hidden;
  margin-bottom: 16px;
  border-radius: 20px;
  height: 126px;
}

.BlogCard__tags {
  display: flex;
}

.BlogCard__tag {
  margin-right: 8px;
  margin-bottom: 8px;
}

.BlogCard__title {
  @include text-overflow(4);

  margin-top: 4px;
  margin-bottom: 22px;
  height: 96px;
}

.BlogCard__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}

.BlogCard__timeToRead {
  display: flex;
  color: $color-dark-grey;
}

.BlogCard__clockIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1px;
  margin-right: 10px;
  width: 22px;
  height: 22px;
}

.BlogCard__arrowWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #fff;
  transition: background-color $transition-main;
}

.BlogCard__arrow {
  width: 22px;
  height: 22px;
  transition: color $transition-main;
}