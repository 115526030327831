@import 'src/styles/media';

.HomeIntroBlock {
  display: flex;
  overflow: hidden;
  padding-top: 32px;
  min-height: calc(100vh - 98px);
  background: linear-gradient(102.16deg, #f5f8ff 6.17%, #e1e3f9 96.98%);

  @include media-up(breakpoint-md) {
    padding-top: 132px;
    min-height: 114vh;
  }

  @include media-up(breakpoint-lg) {
    padding-top: 104px;
    min-height: auto;
  }

  @media screen and (min-width: 592px) {
    padding-top: 86px;
  }
}

.HomeIntroBlock__container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
