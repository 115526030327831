.DeveloperSliderMobile {
  overflow: hidden;
  border-radius: 20px;
  padding: 34px;
  background: linear-gradient(102.16deg, #f5f8ff 6.17%, #e1e3f9 96.98%);
}

.DeveloperSliderMobile__arrows {
  margin-top: 26px;
}

.DeveloperSliderMobile__slide {
  @media screen and (min-width: 524px) {
    width: 426px;
  }
}