@import 'src/styles/tokens/colors';
@import 'src/styles/media';

.StoryCardAvatar {
  display: flex;
  align-items: center;
  margin-top: 22px;
}

.StoryCardAvatar__img {
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  min-width: 52px;

  @include media-up(breakpoint-md) {
    width: 62px;
    height: 62px;
    min-width: 62px;
  }
}

.StoryCardAvatar__content {
  margin-bottom: -18px;
}

.StoryCardAvatar__name {
  margin-bottom: 2px;
  color: $color-dark-blue;
}

.StoryCardAvatar__position {
  min-height: 48px;
  color: $color-dark-blue;
}