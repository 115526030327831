@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';

.BrowseOptionsCard {
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 14px 22px;
  background-color: $color-bg;
  transition: background-color $transition-main, transform $transition-main;
  cursor: pointer;

  &:hover {
    background-color: $color-original-blue;
    transform: scale(1.01);

    .BrowseOptionsCard__title {
      color: #fff;
    }
  }
}

.BrowseOptionsCard__title {
  transition: color $transition-main;
}

.BrowseOptionsCard__icon {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.BrowseOptionsCard__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: #fff;
}