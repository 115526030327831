@import 'src/styles/tokens/colors';
@import 'src/styles/media';

.OptionsMainCard {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  padding: 22px 16px 102px;
  background: linear-gradient(180deg, #fff 0%, #373af5 196.66%);

  @include media-up(breakpoint-md) {
    padding: 26px 324px 26px 24px;
  }

  @include media-up(breakpoint-lg) {
    padding-right: 24px;
  }
}

.OptionsMainCard__text {
  color: $color-dark-blue;
}

.OptionsMainCard__icon_big {
  display: none;

  @include media-up(breakpoint-lg) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
  }
}

.OptionsMainCard__icon_small {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  @include media-up(breakpoint-md) {
    left: auto;
    right: 0;
    bottom: 0;
  }

  @include media-up(breakpoint-lg) {
    display: none;
  }
}