@import 'src/styles/media';

.HomePlatformBlock__title {
  margin-bottom: 24px;
  text-align: center;

  @include media-up(breakpoint-md) {
    margin-bottom: 48px;
  }

  @include media-up(breakpoint-lg) {
    margin-bottom: 60px;
  }
}

.HomePlatformBlock__cards {
  display: flex;
  flex-direction: column;

  @include media-up(breakpoint-md) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.HomePlatformBlock__card {
  flex-basis: 33%;

  & + & {
    margin-top: 24px;
  }

  @include media-up(breakpoint-md) {
    & + & {
      margin-top: 0;
      margin-left: 32px;
    }
  }
}