@import 'src/styles/tokens/colors';
@import 'src/styles/mixins';
@import 'src/styles/media';

.DevelopersText {
  position: relative;

  @include media-up(breakpoint-xl) {
    margin-left: 48px;
  }

  @include media-up(breakpoint-xll) {
    margin-left: 70px;
  }
}

.DevelopersText__title {
  margin-bottom: 24px;
  white-space: unset;
  color: $color-dark-blue;

  br {
    display: none;
  }

  @include media-up(breakpoint-md) {
    padding-left: 60px;

    br {
      display: block;
    }
  }

  @include media-up(breakpoint-xl) {
    margin-bottom: 36px;
    padding-top: 48px;
    padding-left: 0;
  }
}

.DevelopersText__list {
  @include list-reset;
}

.DevelopersText__itemWrapper {
  & + & {
    margin-top: 14px;
  }

  @include media-up(breakpoint-md) {
    & + & {
      margin-top: 16px;
    }
  }
}

.DevelopersText__listItemWrapper {
  @include media-down(breakpoint-md) {
    position: relative;
    padding-left: 28px;
  }
}

.DevelopersText__listItem {
  color: $color-dark-blue;

  @include media-up(breakpoint-md) {
    padding-left: 180px;

    & + & {
      margin-top: 16px;
    }
  }

  @include media-up(breakpoint-xl) {
    padding-left: 100px;
  }
}

.DevelopersText__listItem_accent {
  margin-bottom: 16px;
  color: $color-original-blue;

  @include media-up(breakpoint-md) {
    margin-bottom: 22px;
    padding-left: 160px;
  }

  @include media-up(breakpoint-xl) {
    padding-left: 80px;
  }
}

.DevelopersText__listItemIcon {
  display: none;

  @include media-down(breakpoint-md) {
    position: absolute;
    left: -16px;
    top: 50%;
    display: block;
    transform: translateY(-50%);
  }
}

.DevelopersText__icon {
  display: none;

  @include media-up(breakpoint-md) {
    position: absolute;
    left: 0;
    top: -62px;
    display: block;

    svg {
      height: auto;
    }
  }

  @include media-up(breakpoint-xl) {
    left: -68px;
    top: 0;
    z-index: 1;
    height: 458px;
  }
}