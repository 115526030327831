@import 'src/styles/tokens/colors';
@import 'src/styles/tokens/transitions';
@import 'src/styles/media';
@import 'src/styles/mixins';

.StoryCard {
  --g: #000, #0000 1deg 179deg, #000 180deg;
  --mask: conic-gradient(from -135deg at top 82px left 38px, var(--g)) 0 0 / 100% 100% no-repeat;

  display: block;
  mask: var(--mask);
  padding: 26px 12px 12px;
  background-color: transparent;

  &:hover {
    .StoryCard__link {
      color: #fff;
      background-color: $color-original-blue;
    }
  }
}

.StoryCard__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 32px 12px;
  min-height: 514px;
  background-color: #fff;

  @include media-up(breakpoint-md) {
    padding: 62px 32px 48px;
  }
}

.StoryCard__logo {
  position: relative;
  margin-left: auto;
  height: 28px;
  min-width: 115px;

  @include media-up(breakpoint-md) {
    height: 44px;
    min-width: 180px;
  }
}

.StoryCard__description {
  @include text-overflow(5);

  margin-top: 18px;
  margin-bottom: 20px;
  min-height: 120px;
}

.StoryCard__link {
  transition: background-color $transition-main, color $transition-main;
}