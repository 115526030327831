@import 'src/styles/tokens/colors';
@import 'src/styles/media';

.DomainOptions__grid {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 22px;

  @include media-up(breakpoint-md) {
    column-gap: 24px;
    grid-template-columns: 1fr 1fr;
    row-gap: 28px;
  }

  @include media-up(breakpoint-lg) {
    column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
  }

  @include media-up(breakpoint-xl) {
    grid-template-columns: 246px repeat(3, 1fr);
    grid-template-rows: 164px 164px;
  }
}

.DomainOptions__mainCard {
  @include media-up(breakpoint-md) {
    grid-column: 1 / 3;
  }

  @include media-up(breakpoint-lg) {
    grid-column: auto;
    grid-row: 1 / 3;
  }
}

.DomainOptions__button {
  margin: 44px auto 0;
  padding: 12px 44px;
  font-weight: 400;
  font-size: 18px;
  height: auto;
}

.DomainOptions__showMore {
  color: $color-dark-grey;
}