@import 'src/styles/tokens/colors';
@import 'src/styles/media';

.DeveloperSlide__content {
  position: relative;
  z-index: 2;
  margin-top: 24px;
  padding: 0 20px;
  height: 134px;
  max-width: 402px;
}

.DeveloperSlide__title {
  margin-bottom: 16px;
  color: $color-original-blue;
}

.DeveloperSlide__icon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.DeveloperSlide__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.DeveloperSlide__backedBy {
  margin-right: 32px;
  min-width: 78px;
  color: $color-dark-blue;
}

.DeveloperSlide__image {
  position: relative;
  height: 302px;
}

.DeveloperSlide__imageBg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.DeveloperSlide__avatar {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-top: 54px;
  width: 220px;
  height: 220px;

  img {
    object-fit: contain;
  }

  @include media-up(breakpoint-sm) {
    padding-top: 0;
    width: 294px;
    height: 294px;
  }
}