@import 'src/styles/tokens/colors';
@import 'src/styles/media';

.ProfileCard {
  display: flex;
  flex-direction: column;
}

.ProfileCard__imageWrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 14px;
  border-radius: 20px;
  height: 58vw;

  @include media-up(breakpoint-md) {
    height: 260px;
  }

  @include media-up(breakpoint-lg) {
    height: 300px;
  }

  @include media-up(breakpoint-xl) {
    height: 360px;
  }
}

.ProfileCard__title {
  margin-bottom: 8px;
}

.ProfileCard__description {
  color: $color-dark-grey;
}