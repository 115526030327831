@import 'src/styles/media';

.DetailedProfileCards {
  display: flex;
  flex-direction: column;

  @include media-up(breakpoint-md) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.DetailedProfileCards__card {
  width: 100%;

  & + & {
    margin-top: 26px;
  }

  @include media-up(breakpoint-md) {
    width: calc(50% - 16px);

    & + & {
      margin-top: 0;
    }
  }

  @include media-up(breakpoint-lg) {
    width: calc(50% - 50px);
  }
}
